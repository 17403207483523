import React from 'react'
import styled from 'styled-components'
import { StyledLinkItemText, StyledListItem, StyledListItemGrid } from './styledMobileMenuItem'
import Grid from '@material-ui/core/Grid'
import { Icon } from '../../icon/icon'
import { Link, LinkProps } from '../../link/link'
import { StyledOverviewChevron } from '../../NavigationDropdown/styledNavDropdownUtilityBar'
import { CircleIcon } from '../../NavigationDropdown/circleIcon'

export type MobileMenuLinkItemProps = React.ComponentProps<typeof StyledListItem> &  Pick<LinkProps, 'href'> & {
  isTitle?: boolean
  setClosed?: () => void
}

export const StyledCheveronRight = styled(Icon)`
  color: ${props => props.theme.palette.primary.main};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-right: ${props => props.theme.typography.pxToRem(8)};
`

export const MobileMenuLinkItem: React.FC<MobileMenuLinkItemProps> = ({ children, href, isTitle, setClosed, ...props }) => {
  return (
    <Link href={href} passHref>
      <StyledListItem onClick={href !== '#' ? setClosed : () => {}} $secondary={!isTitle} $isTitle={isTitle} { ...props }>

        <StyledListItemGrid container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
          <Grid item xs={10}>

            <StyledLinkItemText $isTitle={isTitle}>
              { children }
            </StyledLinkItemText>
          </Grid>
          {isTitle && href !== '#' && (
            <StyledOverviewChevron>
              <CircleIcon />
            </StyledOverviewChevron>
          )}
        </StyledListItemGrid>
      </StyledListItem>
    </Link>
  )
}
